import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Landing from "../components/landingSection";
import ImageRight from "../components/imageRight";
import Image from "../components/imageProcessing";
import Icon_list from "../components/VPN_COMP/icon_list";
import Tick_paragaraph from "../components/VPN_COMP/Tick_paragaraph";
import PricingCard from "../components/VPN_COMP/pricing_card";
import VPS_featuers from "../components/VPN_COMP/VPS_featuers";

/* eslint react/prop-types: 0 */

function ssl() {
	return (
		<Layout>
			<SEO
				keywords={[
					`secure web browsing`,
					`most secure browser`,
					`browsers`,
					`High Speed Network`,
					"Unlimited Server Switching",
					"most secure internet browser",
					"best privacy browser",
					"most secure browser 2021",
					"new browser",
					"most private browser",
					"secure web browser",
					"secure browsers",
					"most secure browser for android",
					"privacy browser",
					"best private browser",
					"how to browse privately",
					"best private browser windows",
					"untraceable web browsing",
					"best web browser for android",
					"safest browser",
					"best browser for privacy",
				]}
				title="Domain Names, Websites, Hosting & Online Marketing Tools - Hostylus"
				lang="en"
				image={"/hostylus-card-preview.jpg"}
				description="Easy VPN set-up that allows you to access any network from any device without data leakage. Feel safe to enjoy a fast browsing experience anywhere and anytime."
			/>

			<Landing
				image={
					<Image
						image={"vpnlanding.png"}
						alt={" blue laptop screen with vpn icon"}
					/>
				}
				header={"Secure & Protect Your Web Browsing"}
				sub_header={"High speed, secure, and easy to use with instant setup. "}
				btnText={"Get started"}
				btnURL={"#pricing"}
				hideSecondary_btn={"hidden"}
			/>
			<Tick_paragaraph
				className="mobile:flex-row-reverse"
				type={1}
				header={"Get secure and private access to the internet."}
				description={
					"Shield your personal data and get peace of mind each time you use public Wi-Fi, access personal and work accounts on the road, or just want to keep your browsing history to yourself."
				}
				image={
					<Image
						image={"vpn/1.svg"}
						alt={" blue vpn icon showing protection process "}
					/>
				}
			/>

			<Tick_paragaraph
				className="mobileflex-row-reverse"
				type={0}
				header={"Stream and browse without limitations."}
				description={
					"From video streaming to social networks, our VPN works anywhere and allows you to access the sites and apps you love. Plus fast speeds for easy browsing and no more buffering or long waits."
				}
				image={<Image image={"vpn/2.svg"} alt={" video loading screen"} />}
				dir={"flex-row-reverse"}
			/>

			<div className="p-16 m-auto mobile:p-4 tablet:p-4">
				<ImageRight
					header={"Protect all your devices."}
					hideShopNow={"hidden"}
					hideLearnMore={"hidden"}
					hideArrow={"hidden"}
					smallHeader={"1-click-setup VPN for Mac, Windows, iOS, and Android."}
					desc={
						<>
							<p>
								Access the internet from anywhere, whether you&apos;re on a
								computer, smartphone, or tablet. Auto-configuration is supported
								for all major platforms* allowing you to surf unrestricted with
								no coding or complex setup required.{" "}
								<span className="font-semibold">
									Simply download and connect.
								</span>
							</p>
							<p className="text-sm my-2">
								* Other platforms can be configured manually.
							</p>
						</>
					}
					image={
						<div className="shadow-xl w-max mx-auto mobile:w-4/5">
							<Image image={"vpn/3.svg"} alt={" protection for all devices"} />
						</div>
					}
					btnLink="#"
				/>
			</div>
			<section
				id="pricing"
				className="bg-gradient-to-r from-blue-600 via-blue-700 to-blue-900  w-full py-20 mx-auto text-center   mobile:pb-3 tablet:mx-5 mobile:w-full mobile:px-3 tablet:w-full laptop:w-full"
			>
				<h2 className="text-4xl capitalize text-white font-extrabold text-center w-2/3 m-auto mobile:w-full mobile:text-2xl mobile:px-4 tablet:w-5/6 laptop:w-5/6">
					Just one subscription covers and protect up to 5 devices
					simultaneously.
				</h2>
				<div className="flex flex-row">
					<PricingCard />
				</div>
			</section>
			<section className="w-full m-auto my-16 text-center">
				<h2 className="font-extrabold text-center text-4xl mobile:w-full mobile:text-2xl mobile:px-4">
					All Subscription Plans include:
				</h2>
				<Icon_list />
			</section>

			<section className="w-full m-auto mt-16 text-center p-4 tablet:p-8">
				<h2 className="font-extrabold mb-16 text-center text-4xl mobile:w-full mobile:text-2xl mobile:px-4 mobile:mb-8">
					Blazing fast speeds, unlimited bandwidth
				</h2>

				<Image
					image={"vpn/last-section.svg"}
					alt={"protection that has no geographical limits"}
					className="shadow-2xl"
				/>
			</section>

			<section className="text-center m-auto w-full ">
				<VPS_featuers />
			</section>
		</Layout>
	);
}

export default ssl;
