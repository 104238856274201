import React from "react";
import PropTypes from "prop-types";
import Secure from "../../DB/vpn/secure";
import Stream from "../../DB/vpn/Stream";

function Tick_paragaraph(propss) {
  const ContentType = propss.type;
  // console.log(ContentType);

  return (
    <div
      className={`w-full m-auto flex ${propss.dir} mobile:mt-8   mobile:flex-col-reverse `}
    >
      <div className="w-1/2 px-32 py-16 mobile:px-6  mobile:w-full tablet:px-4 laptop:px-16">
        <div>
          <h2 className="font-extrabold text-4xl py-3 mobile:text-center mobile:text-3xl">
            {propss.header}
          </h2>
          <p className=" text-lg text-gray-500 leading-normal py-3 mobile:text-center">
            {propss.description}
          </p>
        </div>

        {(() => {
          if (ContentType) {
            // console.log(ContentType);
            return Secure.map((items, key) => (
              <div key={key} className=" py-2 mobile:px-2 ">
                <div className="mobile:text-center px-2 flex flex-row">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM15.1013 8.85406L9.87293 14.0825C9.57797 14.3774 9.17781 14.5432 8.76062 14.5432C8.34344 14.5432 7.94332 14.3774 7.64832 14.0824L4.89879 11.3329C4.28449 10.7186 4.28449 9.72258 4.89883 9.10824C5.51316 8.49398 6.50914 8.49395 7.12344 9.10828L8.76066 10.7456L12.8767 6.62953C13.4911 6.01523 14.487 6.01523 15.1014 6.62957C15.7156 7.24375 15.7156 8.23977 15.1013 8.85406Z"
                      fill="#00C891"
                    />
                  </svg>
                  <span className="pl-3">{items.description}</span>
                </div>
              </div>
            ));
          } else {
            return Stream.map((items, key) => (
              <div key={key} className=" py-2 mobile:px-6 ">
                <div className="mobile:text-center px-2 flex flex-row ">
                  <svg
                    className="inline mr-3"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM15.1013 8.85406L9.87293 14.0825C9.57797 14.3774 9.17781 14.5432 8.76062 14.5432C8.34344 14.5432 7.94332 14.3774 7.64832 14.0824L4.89879 11.3329C4.28449 10.7186 4.28449 9.72258 4.89883 9.10824C5.51316 8.49398 6.50914 8.49395 7.12344 9.10828L8.76066 10.7456L12.8767 6.62953C13.4911 6.01523 14.487 6.01523 15.1014 6.62957C15.7156 7.24375 15.7156 8.23977 15.1013 8.85406Z"
                      fill="#00C891"
                    />
                  </svg>
                  <h2>{items.description}</h2>
                </div>
              </div>
            ));
          }
        })()}
      </div>
      <div className="w-1/2 m-auto tablet:ml-5 mobile:w-full ">
        <div className=" shadow-xl  w-max  mx-auto mobile:w-4/5">
          {propss.image}
        </div>
      </div>
    </div>
  );
}
export default Tick_paragaraph;

Tick_paragaraph.PropTypes = {
  header: PropTypes.string.isRequired, // must be a string and defined
  description: PropTypes.string.isRequired, // must be a string and defined
  type: PropTypes.string.isRequired, // must be a string and defined
  dir: PropTypes.string, // must be a string and defined
  image: PropTypes.string.isRequired, // must be a string and defined
};
