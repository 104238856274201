import React from "react";
import { graphql, useStaticQuery } from "gatsby";

function pricingCard() {
  const data = useStaticQuery(graphql`
    query vpnQuery {
      allProducts(filter: { productID: { eq: 33 } }) {
        nodes {
          productID
          productGroupID
          name
          description
          monthly
          quarterly
          semiannually
          annually
          biennially
          triennially
        }
      }
    }
  `);
  const Items = [
    {
      price: data.allProducts.nodes[0].monthly,
      duration: "1 Month",
    },
    {
      price: data.allProducts.nodes[0].quarterly,
      duration: "3 Months ",
    },

    {
      price: data.allProducts.nodes[0].semiannually,
      duration: "6 Months",
    },
    {
      price: data.allProducts.nodes[0].annually,
      duration: "1 Year",
    },
  ];
  var stripedHtml = data.allProducts.nodes[0].description.replace(
    /<[^>]+>/g,
    ""
  );
  var description = stripedHtml.split("\n");
  return (
    <div className="grid grid-cols-4 m-auto text-center mobile:grid-cols-1 tablet:grid-cols-2 ">
      {Items.map((item) => (
        <div
          key={Math.random()}
          className="mx-3 p-8 bg-white border-solid border-2 rounded-lg my-16  mobile:my-3 mobile:w-72 mobile:p-3 tablet:w-80 tablet:mx-5"
        >
          <p className="  text-xl mobile:text-2xl text-center font-semibold my-4">
            {item.duration}
          </p>
          <p className="py-2 font-semibold text-black text-4xl text-center">
            ${item.price} USD
          </p>
          <ul className="m-auto text-center text-gray-600 leading-9 py-4">
            {description.slice(1).map((element, i) => (
              <li key={i}>{element}</li>
            ))}
            {/* <li>No Restrictions</li>
            <li>High Speed Network</li>
            <li>Unlimited bandwidth</li>
            <li>256-bit AES Encryption</li> */}
          </ul>
          <button className="mt-4 px-10 py-2 bg-blue-600  text-white rounded-md">
            <a>signup</a>
          </button>
        </div>
      ))}
    </div>
  );
}
export default pricingCard;
