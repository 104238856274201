export default [
  {
    description: "Access blocked websites",
  },
  {
    description: "Watch streaming services anywhere",
  },

  {
    description: "Bypass local internet censorship",
  },
  {
    description: " Play region-locked games ",
  },
  {
    description: "Get better deals online",
  },
  {
    description: "Stay private and anonymous ",
  },
];
