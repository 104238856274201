export default [
  {
    image: "/vpn/4.svg",
    description: "No Restrictions",
  },
  {
    image: "/vpn/5.svg",
    description: "High Speed Network",
  },

  {
    image: "/vpn/6.svg",
    description: "Unlimited bandwidth",
  },
  {
    image: "/vpn/7.svg",
    description: " 256-bit AES Encryption  ",
  },
  {
    image: "/vpn/8.svg",
    description: "OpenVPN, L2TP-IPsec and PPTP protocols",
  },
  {
    image: "/vpn/9.svg",
    description: "Simultaneous connections on up to 5 devices ",
  },

  {
    image: "/vpn/10.svg",
    description: "Unlimited Server Switching ",
  },
  {
    image: "/vpn/11.svg",
    description: "24/7 US Based Support ",
  },
];
