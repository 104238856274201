import React from "react";
import icons from "../../DB/vpn/icon_list_vpn";

function icon_list() {
	return (
		<section className="px-32 py-14 mobile:px-2 tablet:px-4 laptop:px-16">
			<div className="w-full grid grid-cols-4 mx-auto mobile:grid-cols-2 tablet:grid-cols-3">
				{icons.map((items, key) => (
					<div className="text-center mx-auto py-5" key={key}>
						<img
							alt=" black and blue icons"
							className="mx-auto py-5 text-center w-18"
							src={items.image}
						></img>
						<p className="font-semibold text-lg">{items.description}</p>
					</div>
				))}
			</div>
		</section>
	);
}

export default icon_list;
