export default [
  {
    description: "Hide your IP address",
  },
  {
    description: "Protect your online identity",
  },

  {
    description: "No activity logs to track browsing",
  },
  {
    description: " Secure your bank transactions",
  },
  {
    description: "Use military-grade encryption",
  },
  {
    description: "Be safe on public Wi-Fi networks",
  },
];
