import React from "react";

function VPS_featuers() {
  return (
    <section className="px-32 m-auto text-center w-full justify-center  flex flex-row py-14 mobile:px-2  mobile:block tablet:px-4 laptop:px-4">
      <div className="shadow-lg m-5 w-1/5 h-44 py-16 mobile:w-full mobile:m-0 tablet:w-3/5 laptop:w-2/5">
        <p className="text-blue-600 text-5xl pb-1  tablet:text-4xl">1000+</p>
        <h2 className="text-xl">Servers</h2>
      </div>
      <div className="shadow-lg m-5 w-1/5 h-44 py-16 mobile:w-full mobile:m-0 tablet:w-3/5 laptop:w-2/5">
        <p className="text-blue-600 text-5xl pb-1  tablet:text-4xl">40+</p>
        <h2 className="text-xl">Countries</h2>
      </div>
      <div className="shadow-lg m-5 w-1/5 h-44 py-16 mobile:w-full mobile:m-0 tablet:w-3/5 laptop:w-2/5">
        <p className="text-blue-600 text-5xl pb-1 tablet:text-4xl">Unlimited</p>
        <h2 className="text-xl">Bandwidth</h2>
      </div>
    </section>
  );
}

export default VPS_featuers;
